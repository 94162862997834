@media only screen and (max-width: 600px) {
  .logo-icon {
    margin-top: 30px;
    height: 85px;
  }
  .banner-login {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100;
    display: none;
  }
  .topnav {
    background: rgba(255, 255, 255, 1);
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 10px 10px;
  }
  .topnav {
    max-height: 100vh;
    overflow-y: auto;
    padding: 0;
  }
  .page-content {
    padding: 45px 10px 5px;
  }
  .content-wrapper {
    margin-top: 85px;
  }
  .Modal {
    min-width: 250px;
  }
  .after-header {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .btn-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 10px;
  }
  .grid-column {
    flex: 100%;
    max-width: 100%;
    padding: 10px 10px;
  }
  .btn-flex .btn {
    margin-bottom: 10px;
  }
  .small-text {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
  .banner-content h1 {
    font-size: 25px;
    font-weight: 800;
    color: #fff;
  }
  .b-donate-now {
    padding: 10px 25px;
  }
  .noone {
    font-size: 30px;
    font-weight: 800;
    color: #fece0b;
  }
  .sidebar-component {
    height: 75px;
    overflow-y: auto;
  }
  .gallery-grid {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .volunteer-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .icon-box {
    padding: 5px 20px;
  }
  .counter-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .white-bg-ab {
    position: relative;
    background: #fff;
    width: 100%;
    top: 0;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .content-about h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    padding-bottom: 20px;
  }
  .yello {
    color: var(--secondary-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
  }
  .give-donation {
    position: relative;
    margin-top: 20px;
  }
  .banner {
    position: relative;
    width: 100%;
    height: 300px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .heading h3 {
    text-align: center;
    padding-bottom: 20px;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
  }
  .btn-flex {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    /* margin-right: 23px; */

    text-align: center;
    /* margin-bottom: 26px; */
  }
  .date-box {
    position: absolute;
    background: rgba(8, 74, 90, 0.8);
    width: 100px;
    height: 100px;
    top: 24%;
    border-radius: 20px;
  }
  .header {
    display: block;
  }
  .btn-box {
    display: flex;
    justify-content: space-evenly;
    padding: 3% 0%;
  }
  .banner-content {
    position: relative;
    text-align: center;
    padding-top: 15%;
  }
  .rectangle {
    background: #f1f1f1;
    border-radius: 10px;
    padding: 20px;
    /* margin: 10px; */
    /* margin-right: 10px; */
    margin-bottom: 25px;
    box-shadow: 0 3.4px 2.7px -30px rgb(0 0 0 / 6%),
      0 8.2px 8.9px -30px rgb(0 0 0 / 7%), 0 25px 40px -30px rgb(0 0 0 / 20%);
  }
  .volunteer-image h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    letter-spacing: 0em;
    color: #fff;
  }
  .volunteer-image h4 {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;
    letter-spacing: 0em;
    color: #fff;
  }
  .inner-box {
    position: relative;
    display: block;
    background: #fff;
    border: 2px solid #f1f1f1;
    border-radius: 20px;
    margin-bottom: 25px;
    box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 5%);
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .counte-box img {
    width: 50px;
    height: auto;
    padding-bottom: 30px;
  }
  .counte-box h3 {
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    color: #fff;
    line-height: 54px;
    letter-spacing: 0em;
    padding-bottom: 30px;
  }
  .donate-now {
    padding-top: 0px;
  }
  .counte-box h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    color: #fff;
    margin-bottom: 30px;

    line-height: 30px;
    letter-spacing: 0em;
  }
  .testimonial-image-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #f1f1f1;
  }
  .gallery-grid img {
    /* width: 378px; */
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 10px;
  }
  .top-number {
    display: none;
  }

  /* nav */

  .text-box h1 {
    font-size: 25px;
  }

  .mb-d {
    display: block;
  }
  .page-banner {
    position: relative;
    color: #fff;
    text-align: center;
    padding: 40px 0;
    background-color: var(--primary-color);
    z-index: 2;
  }
  .page-banner h1 {
    position: relative;
    font-size: 30px;
    line-height: 1.2em;
    font-weight: 700;
    margin-bottom: 15px;
    color: #fff;
    text-transform: capitalize;
  }
  .box-content h2 {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    padding-bottom: 10px;
  }
  .donation-total {
    padding-bottom: 10px;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}
